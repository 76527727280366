.ledger-accounts-edition-content-wrapper {
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;
    }
}