.root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 48px;

    &.MuiGrid-root {
        width: auto;
        height: auto;
    }

    & .close-icon-container {
        position: absolute;
        right: 0;
        top: 0;

        & .close-icon-button {
            &.MuiIconButton-root:hover {
                background-color: unset;
            }

            & .icon {
                font-size: 24px;
            }
        }
    }
}