.main-container {
    width: 100%;
    min-height: 100vh;
    height: auto;

    &__content {
        height: calc(100% - 60px);
        padding: 100px 0;
    }

    .header-button {
        text-transform: none;
        height: 57px;
    }
}