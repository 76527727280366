.container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    max-width: 60%;
    margin-top: 3%;

    @media (max-width: 800px) {
        max-width: 100%;
    }

    .appbar {
        background-color: #f5f5f500;
        box-shadow: none;
    }

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 800px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .search-box {
        display: flex;
        align-items: center;
        flex: 1;
        margin-right: 16px;

        @media (max-width: 800px) {
            margin-right: 0;
            width: 100%;
            margin-top: 20px;
        }
    }

    .search-input {
        background-color: white;
        border-radius: 4px;
        padding: 2px 4px;
        width: 100%;
    }

    .user-type-select {
        min-width: 150px;
        background-color: white;
        border-radius: 4px;
        margin-left: 16px;

        .MuiInputLabel-shrink {
            transform: translate(0, -6px) scale(0.75);
        }

        .MuiInputLabel-root {
            background-color: #e6e6e600;
            padding: 0 4px;
            top: -5px;
            font-size: 0.8rem;
        }

        .MuiOutlinedInput-root {
            height: 40px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .add-button {
        // background-color: #003057;
        transition: background-color 0.3s ease, color 0.3s ease;

        @media (max-width: 800px) {
            align-self: flex-end;
            width: 100%;
            margin-top: 8px;
        }
    }

    // .add-button:hover {
    //     background-color: #004074;
    // }

    .user-list {
        flex-grow: 1;
        overflow-y: auto;
        margin-top: 16px;
        height: 85%;

        @media (max-width: 800px) {
            height: 90%;
        }
    }

    .user-card {
        display: flex;
        align-items: center;
        padding: 16px;
        margin-bottom: 8px;
    }

    .user-card-avatar {
        margin-right: 16px;
    }

    .user-card-info {
        flex-grow: 1;
    }

    .edit-button {
        color: gray;
    }

    .delete-button {
        color: red;
    }
}

.popover {
    .popover-box {
        padding: 16px;
    }

    .popover-buttons {
        display: flex;
        justify-content: flex-end;
        padding: 8px;
    }

    .cancel-button {
        color: gray;

        &:hover {
            background-color: #cecece;
        }
    }

    .confirm-button {
        color: red;
        background-color: #e6e6e600;

        &:hover {
            background-color: #cecece;
        }
    }
}