.user-box {
    background-color: #FFF;
    top: 0;
    left: 0;
    right: 0;

    @media (max-width: 800px) {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    button.confirm-button {
        color: white;
        background-color: #003057;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            background-color: #004074;
        }
    }
}