.work-groups-table-wrapper {
    width: 100%;
    height: fit-content;

    &.MuiPaper-root {
        padding: 24px 24px 48px 24px;
        border: none;
        border-radius: 8px;
        box-shadow: none;
        transition: none;
    }

    & .__table-container {
        border: 1px solid #e5e5e5;
        border-radius: 8px;

        &.MuiTableContainer-root {
            width: 80%;
            margin: auto;
        }
    }

    & .__table-pagination {
        width: 80%;
        margin: auto;
    }

    .table-header {
        & .__table-cell {
            background-color: #1976d2;
            color: white;
        }
    }

    .table-body {
        & .__table-cell {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}